/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const recaptchaKey = '6LfwwXcbAAAAAI7LcwS0ygElNlpjy_lYZGEzAExx';

export const dataUrl = "/api/app/data/";
export const appUrl = "/api/app";
export const dataUploadsUrl = "/api/uploads/";
export const apiUrl = "/api";

//export const dataUrl = "http://visual.imem.com/api/app/data/";
//export const dataUploadsUrl = "http://visual.imem.com/api/uploads/";
//export const apiUrl = "http://visual.imem.com/api";