/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import data from "./data/data";

const render = async (params) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let enterprise = urlParams.get('enterprise')
    let language =  localStorage.getItem("imem_language");
    console.log("language",language)
    if (language==0) language=null;
    if (language===null) {
        localStorage.setItem("imem_language", 1);
        language = 1;
        console.log("language",language);
    }
    
    if (!enterprise) {
        enterprise = await data.getEnterpriseByDomain();
    }
    console.log("params",enterprise)
    
    import(`./assets/css/sass/main.scss`).then(() => {
        data.load(enterprise,language).then(()=>{           
            require('./AppRenderer');
        })
    })
  
};
render();
