import axios from 'axios';
import {dataUrl, appUrl, dataUploadsUrl} from '../constants/defaultValues';

let __data = {}
let __enterprise = 1;

const getEnterpriseByDomain = async () => {
    const response = await axios.get(dataUrl+"company/");
    return response.data.id;            
}

const load = async (enterprise,language) => 
    axios
        .get(dataUrl+enterprise+"/"+language)
        .then(response => {
            console.log(response.data);
            let str = response.data;            
            __data = str;
            __enterprise = enterprise;
        return true;
    });

const reload = async (language) => 
    axios
        .get(dataUrl+__enterprise+"/"+language)
        .then(response => {
            console.log(response.data);
            let str = response.data;            
            __data = str;
        return true;
    });

const preload = async (q) => 
    axios
        .get(appUrl+"/quotation/"+q)
        .then(response => {
            console.log(response.data);
            let str = response.data;            
        return str;
    });

const get = () =>{
    return __data;
}

const getEnterprise = () =>{
    return __enterprise;
}

export default {
    load,
    reload,
    preload,
    get,
    getEnterprise,
    getEnterpriseByDomain
}